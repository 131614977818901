<template>
  <div>
    <PaymentList
      :payments="payments"
      :length="limit"
      :step="limit"
    />
  </div>
</template>

<script>

  import PaymentList from "@/components/PaymentList"

  export default {
    name: 'Payments',
    components: {
      PaymentList
    },
    data: () => ({
      limit: 100,
      skip: 0,
      sort: 'desc'
    }),
    computed: {
      payments: function() {
        return this.$store.state.payments
      }
    },
    created() {
      this.$store.dispatch('getPayments', {
        limit: this.limit,
        skip: this.skip,
        sort: this.sort
      })
    }
  }
</script>
